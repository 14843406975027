import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import ENV_CONST from './config/constants/envVariables'
// Route-based code splitting
const NotFound = lazy(() => import('./views/NotFound'))
const Marketplace = lazy(() => import('./views/Marketplace'))
const VLADCollection = lazy(() => import('./views/VLADCollection'))
const VLADCollectionDetail = lazy(() => import('./views/VLADCollectionDetail'))
const GenesisDetail = lazy(() => import('./views/GenesisDetail'))
const ShibariDetail = lazy(() => import('./views/ShibariDetail'))
const ChangeNetwork = lazy(() => import('./views/ChangeNetwrok'))
const MyNFT = lazy(() => import('./views/MyNFT'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

declare const window: any

const { BNB_CHAIN_ID } = ENV_CONST
const App: React.FC = () => {
  const { account, connect, chainId } = useWallet()

  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return chainId && chainId.toString() === BNB_CHAIN_ID ? (
    <Router>
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact component={Marketplace} />
            <Route path="/vlad-collection/:collection" exact component={VLADCollection} />
            <Route path="/vlad-collection/:collection/:tradeId" exact component={VLADCollectionDetail} />
            <Route path="/my-nft/genesis/:id" exact component={GenesisDetail} />
            <Route path="/my-nft/shibari/:id" exact component={ShibariDetail} />
            {/* <Route path="/vlad-detail/:id" exact component={VLADCollectionDetail} /> */}
            <Route exact path="/my-nft">
              {!account ? <Redirect to="/" /> : <MyNFT />}
            </Route>
            <Route exact path="/genesis-collection">
              <Redirect to="/vlad-collection/genesis" />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
    </Router>
  ) : (
    <Suspense fallback={<PageLoader />}>
      <ChangeNetwork />
    </Suspense>
  )
}

export default React.memo(App)
