export default {
  cake: {
    56: '0x42bA7BbDDEcb471c1e1Fe08636918952b6C19019',
    97: '0xFecBE190631196e73A879b317D8A51CC4fE6cB0B',
  },
  life: {
    56: '0x50f4220C82c9325dC99f729C3328FB5c338BEaae',
    97: '0xADA2270B0CB5b6254d3d48A6fEE55b72693B746A',
  },
  bep: {
    57: '',
    97: '0x39C957F7Afc785f3642F6bC3e2a2296EFc0201c1',
    // 4002:'0x56789A1644d0735F9A7Ee258e84d2B0c3a21A3e8',
    // 250:'',
  },
  masterChef: {
    56: '0x4dC79Cb5330f8403fA2346C894EA0F19701cd7C9',
    97: '0x5eAdf1a622B441Fb53D2b04D2DBE08078c9b0Bc6',
  },
  lifeMasterChef: {
    56: '0x19823254C1E577cc466EAd010A03e4496A2C77d4',
    97: '0xE37Aa693d2Ab77721E11e55bfBC723cf4457b78E',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
  },
  nftFarm: {
    56: '0x08d2cBc5EFd1B56034F4628bB32e947C0d86BbB1',
    97: '0xfC9728527a307775F6179824661187525608FC1D',
    // 4002:'0x9AD9D3dBA66a9FF56f6C55eaA001cA76197552f9',
    // 250:'',
  },
  nft_genesis: {
    56: '0x3F7C7C24fFA2ceFfaACE11B39D5b8a575A4B0674',
    97: '0x9eB26f788bBD15BDFB4E36931B30239e364b6A8f',
    // 4002:'0x77449858dDf820C4289926f2216Caaa344c099E9',
    // 250:'',
  },
  nftFarmV2: {
    56: '0xABf2Fb308f3614ECcFa4352Bc874Ef09ABaA7151',
    97: '0xd3cEe320aa195F4A13A3A26748d061A2B25d89c2',
    // 4002:'0x82C626e38bC5e0a38fa89102bB8fEd047315C798',
    // 250:''
  },
  nft_shibari: {
    56: '0xf426F0aFaf912105C119CbD69aedc9e98643d0A1',
    97: '0x90D34344238377Fc9e28EE8bDE830c04f0145aAA',
    // 4002:'0xeae383706f2C3Aaaa417974059C71F847528D414',
    // 250:''
  },
  nft_lite_market: {
    56: '0x227F4899B8E6606FB11d631633861928D7E78d31',
    97: '0x0d8b659Bd6Ed86B5B5114037948F67D7be3FaCc0',
    // '0x0c3ed8BEd955D06aD05ff085aec7De18Af4Aa785',
    // '0xd0E13967878cf797D4Cb6A2Cc952B2D7fbe2302E',
    // 4002:"0x1f4ef1Fec189537c5c16a864bFB8e11c1dc191F3",
    // 250:''
  },
}
