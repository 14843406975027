

const ENV_CONST={
    FTM_CHAIN_ID:process.env.REACT_APP_FTM_CHAIN_ID,
    FTM_NODES:[process.env.REACT_APP_FTM_NODE_1], 
    BNB_CHAIN_ID:process.env.REACT_APP_CHAIN_ID,
    BNB_NODES:[process.env.REACT_APP_NODE_1,process.env.REACT_APP_NODE_2,process.env.REACT_APP_NODE_3],
    BSC_LOG_API: process.env.REACT_APP_BSC_LOGS_API_POINT,
    BSC_API_KEY : process.env.REACT_APP_BSC_API_KEY
}

export default ENV_CONST