import addresses from 'config/constants/contracts'
import ENV_CONST from 'config/constants/envVariables'


const chainId = ENV_CONST.BNB_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}
export const getLifeAddress = (chain = chainId) => {
  return addresses.life[chain]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}
export const getLifeMasterChefAddress = () => {
  return addresses.lifeMasterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}

export const getNftFarmAddress = (chain = chainId) => {
  return addresses.nftFarm[chain]
}
export const getGenesisNft = (chain = chainId) => {
  return addresses.nft_genesis[chain]
}

export const getNftFarmV2Address = (chain = chainId) => {
  return addresses.nftFarmV2[chain]
}
export const getShibariNft = (chain = chainId) => {
  return addresses.nft_shibari[chain]
}
export const getNftLiteMarketAddress=(chain=chainId)=>{
  return addresses.nft_lite_market[chain]

}
