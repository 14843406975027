import random from 'lodash/random'
import ENV_CONST from 'config/constants/envVariables'
// Array of available nodes to connect to
const nodes = [...ENV_CONST.BNB_NODES]

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl
