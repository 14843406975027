import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/hooks'
import { Menu as UikitMenu } from '@pancakeswap-libs/uikit'
import config from './config'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.css'
import './style.css'
import './main.css'
import telegramLogo from './telegram.svg'
import twitterLogo from './twitter.svg'
import mediumLogo from './medium.svg'

let vladValue = '0.00'
let lifeValue = '0.00'

fetch(
  'https://api.vlad.finance/price.php?key=6547643&pool=0x60d5e86c0074b56e52a7540b3bf36c399e9f3038&token=0x279d41f3f78fe5c1f0ba41ae963d6e545113c973&decimals=8',
)
  .then((res) => res.json())
  .then(
    (result) => {
      if (result.status === true) {
        vladValue = result.data
      } else {
        vladValue = '0.00'
      }
    },
    () => {
      vladValue = '0.00'
    },
  )
  .catch((error) => {
    vladValue = '0.00'
  })

fetch(
  'https://api.vlad.finance/price.php?key=6547643&pool=0x5ee167b75118125e7d46add5ce61f749bb977a00&token=0x50f4220c82c9325dc99f729c3328fb5c338beaae&decimals=18',
)
  .then((res) => res.json())
  .then(
    (result) => {
      if (result.status === true) {
        lifeValue = result.data
      } else {
        lifeValue = '0.00'
      }
    },
    () => {
      lifeValue = '0.00'
    },
  )
  .catch((error) => {
    lifeValue = '0.00'
  })

//   const NetworkToggle = styled(Button)`
//   height: 22px;
//   background: #171717;
//   margin-right: 10px;
//   margin-left: 10px;
//   padding: 10px;
// `

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const location = useLocation()
  const isHome = location && location.pathname === '/'

  return (
    <div>
      {/* <ul>
          <li style={{ float: 'left' }}>
                <div className="btn-wrap">
                  <span className="btn-first">
                    <img src={networklogo} className="" alt="" />
                    {networkName}
                  </span>
                </div>
              </li>
          <li >
                <span className="btn-first">
                  <NetworkToggle onClick={() => handleChangeNetwork(binanceid)}>
                    <img src={binanceLogo} className="" alt="" />
                  </NetworkToggle>
                  <NetworkToggle onClick={() => handleChangeNetwork(fantomid)}>
                    <img src={fantomLogo} className="" alt="" />
                  </NetworkToggle>
                </span>
              </li>
      </ul> */}
      <UikitMenu
        account={account}
        login={connect}
        logout={reset}
        isDark={isDark}
        currentLang={selectedLanguage && selectedLanguage.code}
        langs={allLanguages}
        setLang={setSelectedLanguage}
        cakePriceUsd={cakePriceUsd.toNumber()}
        links={config}
        priceLink="https://bscscan.com/token/0x50f4220C82c9325dC99f729C3328FB5c338BEaae"
        isHome={isHome}
        {...props}
      />
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 footer-logoblock">
              <ul className="footer-logo">
                <li>
                  <a href="https://vlad.finance" target="_blank" rel="noreferrer">
                    <img src="/assets/images/vladcirclelogo.png" className="img-fluid" alt="Vlad Finance" />
                  </a>
                </li>
              </ul>
              <h4 style={{ color: 'white' }}>AfterLife.Finance</h4>
              <p>
                Come for the Immortality. <br /> Stay for the NFTs.
              </p>
              <ul className="footermenu">
                <li>
                  <a href="https://vlad.finance/token-facts" target="_blank" rel="noreferrer">
                    TOKEN FACTS
                  </a>
                </li>
                <li>
                  <a href="https://vlad.finance/nft" target="_blank" rel="noreferrer">
                    NFTs
                  </a>
                </li>
                <li>
                  <a href="https://vlad.finance/team" target="_blank" rel="noreferrer">
                    TEAM
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-9 footer-sm">
              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <h6 style={{ color: 'white' }}>COMMUNITY</h6>
                  <ul>
                    <li>
                      <a href="https://t.me/VladFinanceOfficial" target="_blank" rel="noreferrer">
                        <img src={telegramLogo} className="img-fluid" alt="" />
                        <span>Telegram</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://vlad-finance.medium.com/" target="_blank" rel="noreferrer">
                        <img src={mediumLogo} className="img-fluid" alt="" />
                        Medium
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/VladFinance" target="_blank" rel="noreferrer">
                        <img src={twitterLogo} className="img-fluid" alt="" />
                        Twitter
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-3">
                  <h6 style={{ color: 'white' }}>$VLAD</h6>
                  <ul>
                    <li>
                      <a
                        href="https://bscscan.com/token/0x279d41f3f78fe5c1f0ba41ae963d6e545113c973"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fbscscanlogo.svg" className="img-fluid" alt="" />
                        BSCScan
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x279d41f3f78fe5c1f0ba41ae963d6e545113c973"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fpancakeswap.svg" className="img-fluid" alt="" />
                        Buy $VLAD
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dex.guru/token/0x279d41f3f78fe5c1f0ba41ae963d6e545113c973-bsc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/dexguru.png" className="img-fluid" alt="" />
                        DexGuru
                      </a>
                    </li>
                    <li>
                      <a href="https://www.coingecko.com/en/coins/vlad-finance" target="_blank" rel="noreferrer">
                        <img src="/assets/menuImages/fcoingecko.svg" className="img-fluid" alt="" />
                        CoinGecko
                      </a>
                    </li>
                    <li>
                      <a href="https://coinmarketcap.com/currencies/vlad-finance/" target="_blank" rel="noreferrer">
                        <img src="/assets/menuImages/fcoinmarketcap.svg" className="img-fluid" alt="" />
                        CoinMarketCap
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://goswappcharts.web.app/?isbsc=true&tokenId=0x279d41f3f78fe5c1f0ba41ae963d6e545113c973"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fgoswapp.svg" className="img-fluid" alt="" />
                        GoSwapp
                      </a>
                    </li>
                    <li>
                      <a href="https://nomics.com/assets/vlad-vlad-finance" target="_blank" rel="noreferrer">
                        <img src="/assets/menuImages/fnomics.svg" className="img-fluid" alt="" />
                        Nomics
                      </a>
                    </li>
                    <li>
                      <a href="https://www.livecoinwatch.com/price/VladFinance-VLAD" target="_blank" rel="noreferrer">
                        <img src="/assets/menuImages/fLiveCoinWatch.svg" className="img-fluid" alt="" />
                        LiveCoinWatch
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-3">
                  <h6 style={{ color: 'white' }}>$LIFE</h6>
                  <ul>
                    <li>
                      <a
                        href="https://bscscan.com/token/0x50f4220C82c9325dC99f729C3328FB5c338BEaae"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fbscscanlogo.svg" className="img-fluid" alt="" />
                        BSCScan
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x50f4220C82c9325dC99f729C3328FB5c338BEaae"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fpancakeswap.svg" className="img-fluid" alt="" />
                        Buy $LIFE
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dex.guru/token/0x50f4220c82c9325dc99f729c3328fb5c338beaae-bsc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/dexguru.png" className="img-fluid" alt="" />
                        DexGuru
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://goswappcharts.web.app/?isbsc=true&tokenId=0x50f4220C82c9325dC99f729C3328FB5c338BEaae"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fgoswapp.svg" className="img-fluid" alt="goswapp" />
                        GoSwapp
                      </a>
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fcoinmarketcap.svg" className="img-fluid" alt="" />
                      CoinMarketCap
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fcoingecko.svg" className="img-fluid" alt="" />
                      CoinGecko
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fnomics.svg" className="img-fluid" alt="" />
                      Nomics
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fLiveCoinWatch.svg" className="img-fluid" alt="" />
                      LiveCoinWatch
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12 col-md-3">
                  <h6 style={{ color: 'white' }}>$aLIFE</h6>
                  <ul>
                    <li>
                      <a
                        href="https://bscscan.com/token/0x42bA7BbDDEcb471c1e1Fe08636918952b6C19019"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fbscscanlogo.svg" className="img-fluid" alt="" />
                        BSCScan
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x42bA7BbDDEcb471c1e1Fe08636918952b6C19019"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fpancakeswap.svg" className="img-fluid" alt="" />
                        Buy $ALIFE
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dex.guru/token/0x42ba7bbddecb471c1e1fe08636918952b6c19019-bsc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/dexguru.png" className="img-fluid" alt="" />
                        DexGuru
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://goswappcharts.web.app/?isbsc=true&tokenId=0x42ba7bbddecb471c1e1fe08636918952b6c19019"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/menuImages/fgoswapp.svg" className="img-fluid" alt="GoSwapp" />
                        GoSwapp
                      </a>
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fcoinmarketcap.svg" className="img-fluid" alt="" />
                      CoinMarketCap
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fcoingecko.svg" className="img-fluid" alt="" />
                      CoinGecko
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fnomics.svg" className="img-fluid" alt="" />
                      Nomics
                    </li>
                    <li className="fdeactive">
                      <img src="/assets/menuImages/fLiveCoinWatch.svg" className="img-fluid" alt="" />
                      LiveCoinWatch
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 fo-copyright">&copy; Vlad Finance.All Rights Reserved</div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Menu
